/* You can add global styles to this file, and also import other style files */
$primary-dark-blue: #0B0F14;
$primary-moss: #144b46;
$primary-off-white: #FBFBFB;

$secondary-tech-moss: #43FFBB;
$secondary-light-grey: #DBDBDB;
$secondary-grey: #7A7A7A;

$moss-palette: (
  50: #88dfd7,
  100: #4ccfc3,
  200: #2fb3a7,
  300: #207b73,
  400: #1a635c,
  500: #144b46,
  600: #0d322f,
  700: #071a18,
  800: #000202,
  900: #000000,
  A100: #80ddd4,
  A200: #34c3b6,
  A400: #1e736b,
  A700: #144b46,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #FFFFFF,
  ),
);

$tech-moss-palette: (
  50: #ffffff,
  100: #ffffff,
  200: #c7ffea,
  300: #80ffd1,
  400: #61ffc6,
  500: #42ffbb,
  600: #24ffaf,
  700: #05fea4,
  800: #00e692,
  900: #00a96b,
  A100: #ffffff,
  A200: #dcfff2,
  A400: #75ffcd,
  A700: #42ffbb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$warn-color: (
  50: #ffffff,
  100: #ffbcbc,
  200: #ff8484,
  300: #ff3d3d,
  400: #ff1e1e,
  500: #ff0000,
  600: #e00000,
  700: #c10000,
  800: #a30000,
  900: #660000,
  A100: #ffffff,
  A200: #ff9999,
  A400: #ff3232,
  A700: #ff0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #FFFFFF,
  ),
);

body, html {
  margin: unset;
  padding: unset;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-image: url("assets/background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(var(--primary-off-white));
  font-family: trasandina, "sans-serif";
}

@font-face{
  font-family:trasandina;
  src:url(assets/fonts/Trasandina-Book.woff2) format("woff2");
  font-weight:400;
  font-display:block
}

@font-face{
  font-family:trasandina;
  src:url(assets/fonts/Trasandina-Thin.woff2) format("woff2");
  font-weight:300;
  font-display:block
}
@font-face{
  font-family:trasandina;
  src:url(assets/fonts/Trasandina-Medium.woff2) format("woff2");
  font-weight:500;
  font-display:block
}

:root {
  --primary-dark-blue: 11,15,20;
  --primary-moss: 20,75,70;
  --primary-off-white: 251,251,251;

  --secondary-tech-moss: 67, 255, 187;
  --secondary-light-grey: 219, 219, 219;
  --secondary-grey: 122, 122, 122;
}

h1 {
  color: $secondary-tech-moss;
}

.des-button {
  border-radius: 4px;
  background: rgba(var(--primary-dark-blue), 0.7);
  border: 1px solid rgba(var(--secondary-tech-moss), 0.7);
  color: rgb(var(--primary-off-white));
  padding: 0.5rem;
  &:hover:not(:disabled) {
    cursor: pointer;
    background: rgba(var(--secondary-tech-moss), 0.3);
  }
  &:disabled {
    background: #7A7A7A77;
    border-color: #7A7A7A;
  }
}

.des-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  textarea, input {
    padding: 0.5rem;
    background: rgba(var(--secondary-tech-moss), 0.05);
    border-left: unset;
    border-top: unset;
    border-right: unset;
    border-bottom: 1px solid rgba(var(--secondary-tech-moss), 0.8);
    border-radius: 4px;
    color: rgb(var(--primary-off-white));
    &:focus {
      outline: 1px solid #9999;
    }
  }
}

//Scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(var(--primary-dark-blue), 0.7);
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--secondary-tech-moss), 0.6);
  &:hover {
    background: rgba(var(--secondary-tech-moss), 0.9);
  }
}

::-webkit-scrollbar-corner {
  background: rgba(var(--primary-dark-blue), 0.7);
}
